<template>
  <div class="d-flex">
    <svg
      class="m-auto"
      :width="width"
      :height="height"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8912 13.1253H17.3912V15.9453L19.8312 17.3553L19.0812 18.6553L15.8912 16.8153V13.1253ZM19.8912 8.12534H5.89124V19.1253H10.5612C10.1312 18.2153 9.89124 17.1953 9.89124 16.1253C9.89124 14.2688 10.6287 12.4884 11.9415 11.1756C13.2542 9.86284 15.0347 9.12534 16.8912 9.12534C17.9612 9.12534 18.9812 9.36534 19.8912 9.79534V8.12534ZM5.89124 21.1253C4.78124 21.1253 3.89124 20.2253 3.89124 19.1253V5.12534C3.89124 4.01534 4.78124 3.12534 5.89124 3.12534H6.89124V1.12534H8.89124V3.12534H16.8912V1.12534H18.8912V3.12534H19.8912C20.4217 3.12534 20.9304 3.33606 21.3054 3.71113C21.6805 4.0862 21.8912 4.59491 21.8912 5.12534V11.2253C23.1312 12.4853 23.8912 14.2153 23.8912 16.1253C23.8912 17.9819 23.1537 19.7623 21.841 21.0751C20.5282 22.3878 18.7478 23.1253 16.8912 23.1253C14.9812 23.1253 13.2512 22.3653 11.9912 21.1253H5.89124ZM16.8912 11.2753C15.6049 11.2753 14.3713 11.7863 13.4618 12.6959C12.5522 13.6054 12.0412 14.839 12.0412 16.1253C12.0412 18.8053 14.2112 20.9753 16.8912 20.9753C17.5281 20.9753 18.1588 20.8499 18.7472 20.6062C19.3357 20.3624 19.8703 20.0052 20.3207 19.5548C20.7711 19.1044 21.1283 18.5698 21.3721 17.9814C21.6158 17.3929 21.7412 16.7623 21.7412 16.1253C21.7412 13.4453 19.5712 11.2753 16.8912 11.2753Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'UpcomingIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
    color: {
      type: String,
      default: 'var(--settings-registration-current-event-button-background)',
    },
  },
};
</script>
